
const statusOptions = [
    // {
    //     label: '待支付',
    //     value: 'UnPaid'
    // },
    {
        label: '待接单',
        value: 'Paid'
    },
    {
        label: '待配送',
        value: 'Accepted'
    },
    {
        label: '配送中',
        value: 'Shipped'
    },
    {
        label: '已完成',
        value: 'Succeed'
    },
    {
        label: '已取消',
        value: 'Closed'
    }
]

export default [
    {
        id: 'fullName',
        label: '昵称',
        formType: 'input',
    },
    {
        id: 'phone',
        label: '手机号',
        formType: 'input',
    },
    // {
    //     id: 'shopName',
    //     label: '店铺名称',
    //     formType: 'input',
    // },
    {
        id: 'status',
        label: '订单状态',
        formType: 'select',
        options: statusOptions
    }
]