import { useEffect, useState } from 'react';
import { Modal, Input, Form, Upload, Space, Button, Select } from 'antd';
import './style.scss';

import { PlusOutlined } from '@ant-design/icons';


export default ({
    FetchApi,
    visible = {},
    onClose,
    productList,
    noticeItem,
    onConfirm
}) => {

    // form 实例
    const [form] = Form.useForm();

    // 是商品类型时再展示商品选择
    const [isProduct, setProduct] = useState('');

    const [productId, setProductId] = useState('');

    const [imgUrl, setImgUrl] = useState('');

    const [picImage, setPicImage] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const beforeUpload = async (file, fileList) => {
        const __fix = file.name.split('.')[1];
        const params = {
            file: file,
            fileName: isProduct === 'Notice' ? `notice.${__fix}` : `${productId}_Special.${__fix}`,
            fileSize: file.size,
            fileType: 'Static',
        }
        let imgUrl = await getSignature(params);
        setImgUrl(imgUrl);
    }
  
    const getSignature = async (payload) => {
        const result = await FetchApi('POST:oss/createSignature', payload);
  
        const formData = new FormData();
  
        formData.append('key', result.ossSignatureDTO.path);
        formData.append('OSSAccessKeyId', result.ossSignatureDTO.keyId);
        formData.append('policy', result.ossSignatureDTO.policy);
        formData.append('signature', result.ossSignatureDTO.signature);
        formData.append('success_action_status', 200);
        formData.append('file', payload.file); // fileInput是一个文件输入元素
  
        await fetch(result.ossSignatureDTO.host, {
          method: 'POST', // 指定请求方法
          body: formData
        })
        let imageUrl = `${result.ossSignatureDTO.host}/${result.ossSignatureDTO.path}`;
        return imageUrl
    }
  
    const getBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
  
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
    };
  
    const handlePicChange = ({fileList: newFileList}) => setPicImage(newFileList);

    const onSelectType = (value) => {
        setProduct(value)
    }

    const onSelectProduct = (value) => {
        setProductId(value);
    }

    useEffect(() => {
        if (noticeItem && Object.keys(noticeItem).length) {
            console.log('noticeItem', noticeItem)
            form.setFieldsValue(noticeItem);
            let mainImg = noticeItem?.url ? [
                {
                  id: 1,
                  url: noticeItem && noticeItem.url
                }
              ] : [];
            setProduct(noticeItem.type);
            setPicImage(mainImg);
        }
    }, [noticeItem])

    return <Modal
        title='新增公告或特价商品'
        open={visible}
        onCancel={onClose}
        footer={null}
    >
        <Form
            form={form}
            labelAlign="right"
            labelCol={{ flex: '100px' }}
            onFinish={(form) => onConfirm({...form, url: imgUrl})}
        >

            <Form.Item
                label="类型"
                name="type"
                rules={[
                    {
                        required: true,
                        message: '请选择类型!',
                    },
                ]}
            >
                <Select
                    placeholder="请选择类型"
                    onChange={onSelectType}
                    options={[
                        {
                            value: 'Notice',
                            label: '公告',
                        },
                        {
                            value: 'Special',
                            label: '特价商品',
                        },
                    ]}
                />
            </Form.Item>

            {
                isProduct === 'Notice' ? null :
                <Form.Item
                    label="商品"
                    name="productId"
                    rules={[
                        {
                            message: '请选择特价商品!',
                        },
                    ]}
                >
                    <Select
                        showSearch
                        onChange={onSelectProduct}
                        placeholder="请选择特价商品"
                        optionFilterProp="label"
                        fieldNames={{label: 'title', value: 'productId'}}
                        options={productList}
                    />
                </Form.Item>
            }
            

            <Form.Item
                label="上传图片"
                name="imgUrl"
                rules={[
                    {
                        required: true,
                        message: '请上传商品或公告图片!',
                    },
                ]}
            >
                <Upload
                    beforeUpload={beforeUpload}
                    onChange={handlePicChange}
                    fileList={picImage}
                    onPreview={handlePreview}
                    listType="picture-card"
                    maxCount='1'>
                    {
                        picImage && picImage.length ? null :
                        <div>
                        
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                    }
                </Upload>
            </Form.Item>

            <Form.Item label="">
                <Space className='bottom-center'>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                    <Button htmlType="button" onClick={onClose}>
                        取消
                    </Button>
                </Space>
            </Form.Item>

        </Form>
    </Modal>
}