import { BrowserRouter, HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import UserCenter from './pages/UserCenter';
import MemberList from './pages/MemberList';
import ProductList from './pages/ProductList';
import ProductDetail from './pages/ProductDetail';
import OrderList from './pages/OrderList';
import LoginWrap from './pages/LoginWrap';
import NoticeList from './pages/NoticeList';
import PageWrap from './pages';
import FetchApi from './utils/request';
import { useEffect, useState } from 'react';

const AppRouter = () => {

    let userInfo = {};
    try {
        userInfo = JSON.parse(sessionStorage.getItem('loginInfo')) || {};
    } catch (err) {
        userInfo = {};
    }

    // 是否登录
    const [loginInfo, setLoginInfo] = useState(userInfo);

    const getShopListByMerchantId = async () => {
        if (!userInfo?.merchantId) {
            return;
        }
        const reslut = await FetchApi(`get:merchant/${userInfo.merchantId}`, {});
        setLoginInfo(reslut?.merchantInfo);
        sessionStorage.setItem('loginInfo', JSON.stringify(reslut?.merchantInfo));

    }

    useEffect(() => {
        getShopListByMerchantId()
    }, [])

    return (<HashRouter>
        <Routes>
            {/* <Route path={"/"} element={<PageWrap loginInfo={loginInfo} ReactNode={<UserCenter FetchApi={FetchApi} />} />} /> */}
            <Route path={"/"} element={<PageWrap loginInfo={loginInfo} ReactNode={<ProductList FetchApi={FetchApi} />} />} />
            
            <Route path={"/product-list"} element={<PageWrap loginInfo={loginInfo} ReactNode={<ProductList FetchApi={FetchApi} />} />} />
            <Route path={"/member-list"} element={<PageWrap loginInfo={loginInfo} ReactNode={<MemberList FetchApi={FetchApi} />} />} />
            <Route path={"/product-detail"} element={<PageWrap loginInfo={loginInfo} ReactNode={<ProductDetail FetchApi={FetchApi} />} />} />
            <Route path={"/order-list"} element={<PageWrap loginInfo={loginInfo} ReactNode={<OrderList FetchApi={FetchApi} />} />} />
            <Route path={"/notice-list"} element={<PageWrap loginInfo={loginInfo} ReactNode={<NoticeList FetchApi={FetchApi} />} />} />

            {/* 登录页面 */}
            <Route path={"/login"} element={<LoginWrap FetchApi={FetchApi} setLoginInfo={setLoginInfo} />} />
            {/* 未匹配到任何路由时跳转到首页 */}
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    </HashRouter>)
}

export default AppRouter;