import React, { useState, useEffect } from 'react';
import SearchWrap from './../../common/SearchWrap';
import { TableColAction, TableWrap } from './../../common/Table';
import ModalCharge from './ModalCharge';
import { message } from 'antd';
import './style.scss';

import searchConfig from './config';
import columns from './columns';

const MemberList = ({
    FetchApi,
}) => {

    // 是否弹出会员
    const [chargeVisible, setChargeVisible] = useState(false);
    // 搜索
    const [filterData, setFilterData] = useState({});
    // 编辑暂存数据
    const [editRecord, setEditRecord] = useState({});
    // 表格数据
    const [tableInfo, setTableInfo] = useState({
        dataList: [],
        pageNumber: 1,
        pageSize: 10,
        total: 0,
    });

    /**
     * 获取合作商列表
     */
    const getUserList = async (payload) => {
        const reslut = await FetchApi('get:user/getList', {
            ...filterData,
            pageSize: tableInfo.pageSize,
            pageNumber: tableInfo.pageNumber,
            ...payload
        }, '加载成功');

        if (reslut.success) {
            console.error('1234343', reslut)
            setTableInfo({
                dataList: reslut?.userInfoList || [],
                total: reslut?.total || 0,
                pageNumber: payload.pageNumber || tableInfo.pageNumber,
                pageSize: payload.pageSize || tableInfo.pageSize,
            })
        }
    }

    /**
     * 会员审核
     */
    const onRecharge = async (payload) => {
        const result = await FetchApi(`put:user/${payload.userId}/audit`);

        if (result.success) {
            message.success('审核通过');
            setChargeVisible(false);
            await getUserList({ pageSize: 10, pageNumber: 1 });
            setEditRecord({})
        }
    }

    // 分页变动，重新获取数据
    const onPaginationChange = (record) => {
        getUserList({ pageSize: record.pageSize, pageNumber: record.pageNumber });
    }

    const onSearch = (params) => {
        setFilterData(params);
        getUserList(params);
    }

    const onReset = () => {
        setFilterData({});
        getUserList({ pageSize: 10, pageNumber: 1, phone: undefined, status: undefined, userName: undefined });
    }

    // 审核弹窗
    const onCharge = (record) => {
        console.log('onCharge', record)
        setEditRecord(record)
        setChargeVisible(true)
    }

    // 操作栏上下架文案
    const onText = (record) => {
        let text = '';
        switch (record.status) {
            case 'Init':
                text = '';
                break;
            case 'Waiting':
                text = '同意';
                break;
            default:
                text = '';
                break;
        }
        return text;
    }

    // 初始化页面
    useEffect(() => {
        getUserList({ pageSize: 10, pageNumber: 1 });
    }, [])

    return <>
        {/* 搜索组件 */}
        <SearchWrap config={searchConfig} onSearch={onSearch} onReset={onReset} />

        {/* 表格 */}
        <TableWrap
            dataSource={tableInfo.dataList}
            columns={columns}
            total={tableInfo.total}
            pageNumber={tableInfo.pageNumber}
            pageSize={tableInfo.pageSize}
            onPaginationChange={onPaginationChange}
            lastColWidth={80}
            scroll={{ x: '100%' }}
        >
            <TableColAction onText={(record) => onText(record)} onClick={(record) => onCharge(record)} />
            {/* <TableColAction text='查看' onClick={onShowDetail} /> */}
            {/* <TableColAction text='删除' onClick={onShowDetail} /> */}
        </TableWrap>

        {/* 会员审核-弹层 */}

        <ModalCharge
            onRecharge={onRecharge}
            visible={chargeVisible}
            editRecord={editRecord}
            onClose={() => setChargeVisible(false)}
        />
    </>
}

export default MemberList;