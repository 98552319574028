import React, { useState, useEffect } from 'react';
import { Input, Button, message, Tag } from 'antd';
import { TableWrap } from './../../../common/Table';
import './style.scss';

export default ({
    form,
    detailInfo = {}
}) => {
    // 初始化 SKU 数据
    const [skuData, setSkuData] = useState([{
        keyId: 'A',
        key: '',
        values: [{ valueId: '0', value: '' }]
    }]);

    // 初始化 SKU 价格数据
    const [skuDataList, setSkuDataList] = useState(generateSkuList(skuData));

    useEffect(() => {
        if (detailInfo?.specs) {
            setSkuData(detailInfo.specs);

            const tempList = detailInfo?.skuInfoList?.map(item => ({
                ...item,
                skuPrice: String(item?.skuPrice?.amount || item?.skuPrice || 1)
            }))

            setSkuDataList(tempList || []);
        }
    }, [])


    // 更新 SKU 数据并重新生成 SKU 列表
    const handleSkuUpdate = (updatedSkuData) => {
        // 保留之前的价格数据
        const oldskuDataList = skuDataList;

        // 生成新的 SKU 组合列表
        const newskuDataList = generateSkuList(updatedSkuData).map(newSku => {
            // 查找旧数据中是否有相同的 skuSpecCode，如果有，保留价格
            const oldSku = oldskuDataList.find(item => item.skuSpecCode === newSku.skuSpecCode);
            return oldSku ? { ...newSku, skuPrice: oldSku.skuPrice } : { ...newSku, skuPrice: '0.01' }; // 默认初始化价格为0
        });

        // 更新状态
        setSkuData(updatedSkuData);
        setSkuDataList(newskuDataList);
    };

    // 新增 SKU 属性配置项
    const onCreateSku = () => {
        const keyIds = skuData.map(i => i?.keyId);
        const newKeyId = getNextAvailableKey(WORDS, keyIds);

        if (!newKeyId) {
            message.warning('无法添加更多的SKU属性');
            return;
        }

        const newSku = {
            keyId: newKeyId,
            key: '',
            values: [{ valueId: '0', value: '' }]
        };

        // 更新 SKU 数据
        handleSkuUpdate([...skuData, newSku]);
    };

    // 为某个 SKU 属性新增值
    const onAddValues = (record) => {
        const valueIds = record.values.map(j => j.valueId);
        const newValueId = getNextAvailableKey(NUMBERS, valueIds);

        if (!newValueId) {
            message.warning('无法添加更多的SKU值');
            return;
        }

        const updatedValues = [...record.values, { valueId: newValueId, value: '' }];
        updateSkuItem(record.keyId, { ...record, values: updatedValues });
    };

    // 删除某个 SKU 值
    const onDelValue = (record, valueToDelete) => {

        if (skuData?.length === 1 && skuData?.[0]?.values?.length === 1) {
            message.warning('最少保留1个SKU');
            return;
        }

        const updatedValues = record.values.filter(value => value.valueId !== valueToDelete.valueId);

        if (updatedValues.length === 0) {
            // 如果删除后没有值了，直接删除该 SKU 属性
            const updatedSkuData = skuData.filter(item => item.keyId !== record.keyId);
            handleSkuUpdate(updatedSkuData);
        } else {
            // 否则更新该 SKU 属性的值
            updateSkuItem(record.keyId, { ...record, values: updatedValues });
        }
    };

    // 更新 SKU 属性项
    const updateSkuItem = (keyId, updatedItem) => {
        const updatedSkuData = skuData.map(item => item.keyId === keyId ? updatedItem : item);
        handleSkuUpdate(updatedSkuData);
    };

    // 更新 SKU 名称或值
    const onInputChange = (record, key, newValue) => {
        const updatedItem = { ...record, [key]: newValue };
        updateSkuItem(record.keyId, updatedItem);
    };

    const onValueChange = (child, newValue, record) => {
        const updatedValues = record.values.map(value => value.valueId === child.valueId ? {
            ...value, value: newValue
        } : value);
        updateSkuItem(record.keyId, { ...record, values: updatedValues });
    };

    // 更新 SKU 价格
    const onSkuPriceChange = (record, value) => {
        const updatedskuDataList = skuDataList.map(item => (
            item.skuSpecCode === record.skuSpecCode ? {
                ...item,
                // skuPrice: value <= 0.01 ? '0.01' : value
                skuPrice: value
            } : item
        ));
        setSkuDataList(updatedskuDataList);
    };

    form.setFieldValue('specs', skuData);
    form.setFieldValue('skuInfoList', skuDataList);

    return (
        <div className='sku-info-wrap'>
            {/* SKU 配置区域 */}
            <div className='sku-info-block'>
                <div className='wrap-title'>规格SKU配置</div>
                {
                    skuData.map((item, idx) => (
                        <div key={item.keyId} className='sku-tag-list'>
                            <div className='sku-key'>
                                <Input
                                    className='inp'
                                    value={item.key}
                                    placeholder='SKU名称'
                                    onChange={(e) => onInputChange(item, 'key', e.target.value)}
                                />
                            </div>
                            <div className='sku-val'>
                                {
                                    item.values.map(child => (
                                        <Input
                                            key={child.keyId}
                                            className='inp'
                                            value={child.value}
                                            placeholder='SKU值'
                                            onChange={(e) => onValueChange(child, e.target.value, item)}
                                            suffix={<span className='iconfont icon-jianhao' onClick={() => onDelValue(item, child)} />}
                                        />
                                    ))
                                }
                                {
                                    item.values.length < 10 && <span
                                        className='iconfont icon-jiahao'
                                        onClick={() => onAddValues(item)}
                                    />
                                }
                            </div>
                        </div>
                    ))
                }
                {skuData.length < 2 && <Button type='dashed' style={{ width: '100%' }} onClick={onCreateSku}>新增配置项</Button>}
            </div>

            {/* 价格表配置区域 */}
            <div className='sku-info-block'>
                <div className='wrap-title'>SKU价格配置</div>
                <TableWrap
                    className="sku-table-list"
                    total={skuDataList?.length || 0}
                    columns={[
                        { title: 'code', dataIndex: 'skuSpecCode', width: 300 },
                        {
                            title: '名称',
                            dataIndex: 'skuSpecs',
                            width: 300,
                            render: (list) => (
                                list?.length > 0
                                    ? list?.map((i, idx) => (<Tag key={i.keyId + idx}>{i.key}:{i.value}</Tag>))
                                    : '-'
                            )
                        },
                        {
                            title: 'SKU价格',
                            dataIndex: 'skuPrice',
                            render: (val, record) => <Input
                                value={val}
                                onChange={(e) => onSkuPriceChange(record, e.target.value)}
                                style={{ width: '100%' }}
                                prefix="￥"
                            />,
                        }
                    ]}
                    dataSource={skuDataList || []}
                />
            </div>
        </div>
    );
};

// 常量：用于生成唯一的 keyId 和 valueId
const WORDS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
const NUMBERS = '0123456789'.split('');

// 获取下一个可用的 key
const getNextAvailableKey = (availableKeys, usedKeys) => {
    return availableKeys.find(key => !usedKeys.includes(key)) || null;
};

// 生成 SKU 组合列表的函数
const generateSkuList = (dataList = []) => {

    if (dataList.length < 1) {
        return [];
    }

    const skuList = [];
    // 递归生成所有 SKU 组合
    const generateCombinations = (attributes, currentIndex, currentSku) => {
        if (currentIndex >= attributes.length) {
            // 生成 skuSpecCode
            const skuSpecCode = attributes.map(attr => `${attr.keyId}: ${currentSku[attr.keyId]}`).join(';');

            // 创建 SKU 规格对象
            const skuSpecs = attributes.map(attr => ({
                key: attr.key,
                keyId: attr.keyId,
                value: attr.values.find(val => val.valueId === currentSku[attr.keyId])?.value,
                valueId: currentSku[attr.keyId]
            }));

            // 将 skuSpecCode 添加到 SKU 列表中
            skuList.push({ skuSpecCode, skuSpecs });
            return;
        }

        const currentAttribute = attributes[currentIndex];
        currentAttribute.values.forEach(value => {
            currentSku[currentAttribute.keyId] = value.valueId;
            generateCombinations(attributes, currentIndex + 1, currentSku);
        });
    };

    // 开始生成 SKU 组合
    generateCombinations(dataList, 0, {});
    return skuList;
};