import React, { useState, useEffect } from 'react';
import { Button, message, Modal } from 'antd';
import SearchWrap from './../../common/SearchWrap';
import { TableColAction, TableWrap, TableHead } from './../../common/Table';
import ModalExport from './ModalExport';
import './style.scss';

import config from './config';
import columns from './columns';

const OrderList = ({
    FetchApi
}) => {

    // 是否弹出订单导出页面
    const [exportVisible, setExportVisible] = useState(false);
    // 搜索
    const [filterData, setFilterData] = useState({});
    // 表格数据
    const [tableInfo, setTableInfo] = useState({
        dataList: [],
        pageNumber: 1,
        pageSize: 10,
        total: 0,
    });

    // 选中的数据
    const [selectData, setSelectData] = useState([]);


    const getOrder = async (value) => {
        const reslut = await FetchApi(value.status === 'Paid' ?
            `put:order/${value.orderId}/accept` :
            `put:order/${value.orderId}/send`);

        if (reslut.success) {
            getOrderList({ pageSize: 10, pageNumber: 1 });
        }
    }

    const onExportFn = () => {
        setExportVisible(true);
    }

    const onGetSelectFn = (type) => {
        if (!selectData.length) {
            message.warning('请先选择数据');
            return;
        } else {
            selectData.map(item => {
                if (type === 'order' && item.status === 'Paid') {
                    getOrder(item);
                } else if (type === 'send' && item.status === 'Accepted') {
                    getOrder(item);
                }
            })
        }
    }

    const onSendFn = () => {

    }

    // 分页变动，重新获取数据
    const onPaginationChange = (record) => {
        getOrderList({ pageSize: record.pageSize, pageNumber: record.pageNumber });
    }

    const onSearch = (params) => {
        setFilterData(params);
        getOrderList(params);
    }

    const onReset = () => {
        setFilterData({});
        getOrderList({
            pageSize: 10,
            pageNumber: 1,
            status: undefined,
            fullName: undefined,
            phone: null
        });
    }

    /**
     * 获取合作商列表
     */
    const getOrderList = async (payload) => {
        const reslut = await FetchApi('get:order', {
            ...filterData,
            pageSize: tableInfo.pageSize,
            pageNumber: tableInfo.pageNumber,
            ...payload
        }, '加载完成');

        if (reslut.success) {
            setTableInfo({
                dataList: reslut?.orderInfoList || [],
                total: reslut?.total || 0,
                pageNumber: payload.pageNumber || tableInfo.pageNumber,
                pageSize: payload.pageSize || tableInfo.pageSize,
            })
        }
    }

    const onTextShow = (record) => {
        let text = '';

        switch (record.status) {
            case 'Paid':
                text = '接单';
                break;
            case 'Accepted':
                text = '配送';
                break;
            default:
                text = '';
                break;
        }
        return text;
    }

    // 初始化页面
    useEffect(() => {
        getOrderList({ pageSize: 10, pageNumber: 1 });
    }, [])

    const onRowSelectionFn = (selectedRowKeys, selectedRows) => {
        console.log('vals222', selectedRowKeys, selectedRows);
        setSelectData(selectedRows);
    }

    return <>
        {/* 搜索组件 */}
        <SearchWrap config={config} onSearch={onSearch} onReset={onReset} />

        <TableHead>
            <Button type="primary" onClick={onExportFn}>导出订单</Button>
            <Button layout="right" onClick={() => onGetSelectFn('order')}>批量接单</Button>
            <Button layout="right" onClick={() => onGetSelectFn('send')}>批量配送</Button>
        </TableHead>

        {/* 表格 */}
        <TableWrap
            dataSource={tableInfo.dataList}
            columns={columns}
            total={tableInfo.total}
            pageNumber={tableInfo.pageNumber}
            pageSize={tableInfo.pageSize}
            lastColWidth={100}
            onPaginationChange={onPaginationChange}
            scroll={{ x: '100%' }}
            rowKey={(data) => {
                return data.orderId
            }}
            rowSelection={{
                // getCheckboxProps: (vals) => {
                //     console.log('vals', vals);
                // },
                onChange: onRowSelectionFn
            }}
        >
            <TableColAction
                onText={onTextShow}
                onClick={getOrder}
                isTip={true}
            />
        </TableWrap>

        <ModalExport
            FetchApi={FetchApi}
            visible={exportVisible}
            onClose={() => setExportVisible(false)}></ModalExport>
    </>
}

export default OrderList;