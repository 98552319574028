var draw = (item) => {
  // 创建 canvas 元素
  const canvas = document.createElement('canvas');
  canvas.width = 800; // 设置宽度为800
  canvas.height = 600; // 设置高度为600
  const context = canvas.getContext('2d');

  // 设置白色背景
  context.fillStyle = '#FFFFFF';
  context.fillRect(0, 0, canvas.width, canvas.height); // 绘制白色底

  // 设置字体和颜色
  context.font = '24px Arial';
  context.fillStyle = '#000';

  // 绘制居中的品牌名称
  context.textAlign = 'center'; // 设置文本居中对齐
  context.fillText(item.merchantBrandName || '孙付集山药', canvas.width / 2, 50); // 品牌名称居中绘制

  // 品牌下方的虚线
  context.setLineDash([5, 5]); // 虚线样式
  context.beginPath();
  context.moveTo(50, 70); // 起始点 (50, 70)
  context.lineTo(canvas.width - 50, 70); // 终点
  context.strokeStyle = '#000';
  context.stroke(); // 绘制虚线

  // 恢复默认文本对齐方式
  context.textAlign = 'left';
  context.font = '20px Arial';
  context.setLineDash([]); // 取消虚线设置

  // 计算最大标签的宽度，"地址"为最长标签
  const label = "地址: ";
  const labelWidth = context.measureText(label).width; // 获取“地址: ”的宽度

  // 设置文本绘制的起始位置
  const x = 50;
  let y = 120; // 设置从虚线下方开始绘制文本
  const lineHeight = 40; // 行高设置为40
  const maxWidth = canvas.width - 100; // 设置文本绘制的最大宽度（减去两侧的边距）

  // Helper function to wrap text ensuring that first line fills fully, and subsequent lines are indented
  function wrapText(context, labelText, valueText, x, y, maxWidth, lineHeight, indentWidth) {
    // 计算出完整的一行，包括标签和值
    let line = labelText;
    let testLine = line + valueText;
    let testWidth = context.measureText(testLine).width;

    // 如果整体内容超出一行，处理折行
    if (testWidth > maxWidth) {
      // 计算第一行可以容纳的部分
      let words = valueText.split('');
      let currentLine = labelText;
      let remainingText = '';

      for (let i = 0; i < words.length; i++) {
        const testLine = currentLine + words[i];
        const testWidth = context.measureText(testLine).width;

        if (testWidth > maxWidth) {
          // 第一行满了，绘制并将剩余的文本保存到 remainingText
          context.fillText(currentLine, x, y);
          remainingText = valueText.slice(i);
          y += lineHeight;
          break;
        } else {
          currentLine = testLine;
        }
      }

      // 折行，后续的行从缩进位置开始绘制
      let indentLine = '';
      for (let i = 0; i < remainingText.length; i++) {
        const testIndentLine = indentLine + remainingText[i];
        const testIndentWidth = context.measureText(testIndentLine).width;

        if (testIndentWidth > maxWidth - indentWidth) {
          context.fillText(indentLine, x + indentWidth, y);
          indentLine = remainingText[i];
          y += lineHeight;
        } else {
          indentLine = testIndentLine;
        }
      }

      // 绘制最后一行
      if (indentLine) {
        context.fillText(indentLine, x + indentWidth, y);
      }
    } else {
      // 如果内容不超过一行，直接绘制
      context.fillText(testLine, x, y);
    }

    return y + lineHeight; // 返回新的y位置
  }

  // 绘制每一行文本，并处理折行
  y = wrapText(context, "姓名: ", item.userName || '无', x, y, maxWidth, lineHeight, labelWidth);
  y = wrapText(context, "电话: ", item.userPhone || '无', x, y, maxWidth, lineHeight, labelWidth);
  // y = wrapText(context, "店铺: ", item.userShopName || '无', x, y, maxWidth, lineHeight, labelWidth);
  y = wrapText(context, "地址: ", `${item.orderCity} ${item.orderDistrict} ${item.userAddressDetail}`, x, y, maxWidth, lineHeight, labelWidth);
  y = wrapText(context, "商品: ", item.productTitle || '无', x, y, maxWidth, lineHeight, labelWidth);
  y = wrapText(context, "规格: ", item.skuTitle || '无', x, y, maxWidth, lineHeight, labelWidth);
  y = wrapText(context, "数量: ", item.orderLineQuantity || '无', x, y, maxWidth, lineHeight, labelWidth);

  // 日期上方的虚线
  context.setLineDash([5, 5]);
  context.beginPath();
  context.moveTo(50, canvas.height - 90); // 日期上方虚线起点
  context.lineTo(canvas.width - 50, canvas.height - 90); // 日期上方虚线终点
  context.stroke();

  // 绘制右下角的日期，不添加前缀
  context.font = '18px Arial';
  context.textAlign = 'right'; // 右对齐
  context.fillText(item.orderTime, canvas.width - 50, canvas.height - 50); // 日期位置

  // 将 canvas 转换为图片并生成下载链接
  const dataUrl = canvas.toDataURL('image/png');
  const link = document.createElement('a');

  // 设置下载文件名为 'miandan/下载的图片.png'
  link.download = `小程序_面单.png`; // 文件名
  link.href = dataUrl;

  // 触发下载
  link.click();
  link.remove();
}
export default draw;