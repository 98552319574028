export default [
    // {
    //     id: 'userName',
    //     label: '用户名称',
    //     formType: 'input',
    // },
    {
        id: 'phone',
        label: '电话',
        formType: 'input',
    },
    {
        id: 'status',
        label: '状态',
        formType: 'select',
        options: [
            { label: '未认证', value: 'Init' },
            { label: '待审核', value: 'Waiting' },
            { label: '已认证', value: 'Valid' },
            { label: '已拒绝', value: 'Invalid' },
        ]
    },
]