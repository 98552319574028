import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import './style.scss';

const Login = ({
    setPageName,
    FetchApi,
    setLoginInfo,
}) => {
    // 是否显示密码
    const [form] = Form.useForm();
    // 是否显示密码
    const [showPwd, setShowPwd] = useState(false);

    // 确认表单
    const onPressEnter = async () => {

        try {
            const vals = await form.validateFields();
            const reslut = await FetchApi(`post:auth/login`, {
                phone: vals.userName,
                password: vals.password
            }, '登录成功')

            if (reslut.success) {
                sessionStorage.setItem('loginInfo', JSON.stringify(reslut?.merchantInfo));
                sessionStorage.setItem('accessToken', reslut?.accessToken);
                setLoginInfo(reslut?.merchantInfo || {});
                setTimeout(() => {
                    window.location.hash = '/'
                }, 500)
            }
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <div className='login-page-container'>
            <h1 className='login-page-title'>登录后台</h1>
            <Form className='login-form-wrap' form={form}>
                <Form.Item
                    className='login-form-item'
                    name='userName'
                    rules={[
                        { required: true, message: '请输入手机号' },
                    ]}
                >
                    <Input
                        placeholder='请输入手机号'
                        prefix={<i className='iconfont icon-user1' />}
                    />
                </Form.Item>
                <Form.Item
                    className='login-form-item'
                    name='password'
                    rules={[
                        { required: true, message: '请输入密码' },
                    ]}
                >
                    <Input
                        type={showPwd ? 'text' : 'password'}
                        placeholder='请输入密码'
                        prefix={<i className='iconfont icon-password' />}
                        suffix={<i className={`iconfont ${showPwd ? 'icon-eye' : 'icon-hide-eye'}`} onClick={() => setShowPwd(!showPwd)} />}
                    />
                </Form.Item>
            </Form>
            <Button onClick={onPressEnter} type='primary' className='login-btn'>登录</Button>
            <div className="login-btns-wrap">
                {/* <span className='login-other-btn' onClick={() => setPageName('register')}>立即注册</span> */}
                <span className='login-other-btn'> </span>
                <span className='login-other-btn' onClick={() => setPageName('resetPwd')}>忘记密码</span>
            </div>
        </div>
    )
}

export default Login;