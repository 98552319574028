import { useState } from 'react';
import { Modal, Input, Form, Upload, Space, Button, message } from 'antd';
import './style.scss';

import { PlusOutlined } from '@ant-design/icons';


export default ({
    FetchApi,
    visible = {},
    onClose,
    onConfirm
}) => {

    let accessToken = sessionStorage.getItem('accessToken');

    // form 实例
    const [formInstance] = Form.useForm();

    return <Modal
        title='新增商品'
        open={visible}
        onCancel={onClose}
        footer={null}
    >
        <Form
            form={formInstance}
            labelAlign="right"
            labelCol={{ flex: '100px' }}
            onFinish={onConfirm}
        >

            <Form.Item
                label="商品名称"
                name="title"
                rules={[
                    {
                        required: true,
                        message: '请输入商品名称!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="原价"
                name="marketPrice"
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="现价"
                name="salePrice"
                rules={[
                    {
                        required: true,
                        message: '请输入商品售卖价格!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item label="">
                <Space className='bottom-center'>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                    <Button htmlType="button" onClick={onClose}>
                        取消
                    </Button>
                </Space>
            </Form.Item>

        </Form>
    </Modal>
}