import React from 'react';
import { Table, Row, Col, Popconfirm, Divider, Pagination } from 'antd';
import defaultColmmns from './columns'
import './style.scss';

/** 表格 **/
export const TableWrap = ({
    className,
    dataSource = [],
    columns = defaultColmmns,
    cellTitle = "操作",
    onDeleteFn,
    render = [
        { text: '编辑', onClick: () => { }, isTip: true },
        { text: '删除', onClick: () => { } },
    ],
    children,
    total = 0,
    pageNumber = 1,
    pageSize = 10,
    onPaginationChange = () => { },
    lastColWidth = '',
    ...tableInfo
}) => (<>
    <Table
        {...tableInfo}
        pagination={false}
        className={`table-wrap ${className}`}
        dataSource={dataSource}
    >
        {
            Array.isArray(columns) && columns.map((item, idx) => (<Table.Column key={idx} {...item} />))
        }
        {
            children && <Table.Column
                title="操作"
                key={"操作"}
                width={lastColWidth}
                fixed="right"
                render={(val, record, idx) => (<Row className='table-column-action-wrap'>
                    {
                        Array.isArray(children) ? children.map((action, idx) => {
                            return (<React.Fragment key={idx}>
                                <Divider type="vertical" style={{ background: '#ddd' }} />
                                {React.cloneElement(action, { record: record })}
                            </React.Fragment>)
                        }) : React.cloneElement(children, { record: record })
                    }
                </Row>)}
            />
        }
    </Table>
    <div
        style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: '12px 0',
        }}>
        <Pagination
            total={total}
            current={pageNumber}
            pageSize={pageSize}
            hideOnSinglePage={false}
            pageSizeOptions={[10, 30, 50]}
            showTotal={(t) => `共 ${t} 条`}
            onChange={(num) => onPaginationChange({ pageNumber: num, pageSize: pageSize })}
            onShowSizeChange={(size) => onPaginationChange({ pageNumber: 1, pageSize: size })}
        />
    </div>
</>);

/** 表格操作列 **/
export const TableColAction = ({
    record = {}, // 表格列数据
    okText = '确定',
    cancelText = '取消',
    placement,
    title = "温馨提示",
    isTip = false,
    onClick = () => { },
    onText = () => { }, // 根据数据来显示指定text文案
    ...props
}) => {

    const tmepText = typeof props.children === 'string' ? props.children : onText?.(record) || props.text;

    return (isTip ?
        <Popconfirm
            title={title}
            description={`您确定要${tmepText || '这么做'}吗？`}
            okText={okText}
            cancelText={cancelText}
            placement={placement}
            onConfirm={() => onClick(record)}
        >
            <Col className='table-action-node' {...props} >{tmepText}</Col>
        </Popconfirm> : <Col
            className='table-action-node'
            {...props}
            onClick={() => onClick(record)}
        >{tmepText}</Col>
    )
}

/** 元素默认渲染，位置居左 **/
const defaultExport = (item, layout, propsLayout) => (
    layout === 'left' && propsLayout === undefined ?
        <div
            key={new Date().valueOf() * Math.random()}
            className="table-head-action"
        >{item}</div> : null
)

/** 元素渲染 **/
const renderNode = (children, layout) => {
    const propsLayout = children?.props?.layout;
    if (Array.isArray(children)) {
        return children.filter(i => i).map((item, idx) => (
            (item?.props?.layout === layout) ?
                <div key={idx} className='table-head-action'>{item}</div> :
                defaultExport(item, layout, item.props.layout)
        ))
    } else if (propsLayout === layout) {
        return (<div key={propsLayout} className='table-head-action'>{children}</div>)
    } else if (propsLayout === undefined && layout === 'left') {
        return (<div key={propsLayout} className='table-head-action'>{children}</div>)
    }
}

/** 表格头部操作栏 **/
export const TableHead = ({ children = [] }) => (
    /** child 子元素的 props 应有一个layout属性来确定位置 **/
    <div className='table-head-wrap'>
        <div className='table-head-left-node'>
            {renderNode(children, 'left')}
        </div>
        <div className='table-head-right-node'>
            {renderNode(children, 'right')}
        </div>
    </div>
);
