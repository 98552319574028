import FormatPrice from './../../utils/formatPrice'
import { Image, Tag } from 'antd';

const map = {
    'Online': <Tag color="success">上架</Tag>,
    'Draft': <Tag color="default">草稿</Tag>,
    'Delete': <Tag color="error">删除</Tag>,
    'Offline': <Tag color="warning">下架</Tag>
}

export default [
    {
        title: '主图',
        dataIndex: 'picUrl',
        width: 100,
        render: val => <Image src={val} style={{
            width: 64,
            height: 64,
            objectFit: 'contain',
            background: '#ddd',
            display: 'block'
        }} />
    },
    {
        title: '商品信息',
        dataIndex: 'title',
        width: 320,
        render: (val, record) => (<div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                fontSize: 12,
                lineHeight: 1.5,
            }}>
            <span>商品ID：{record?.productId}</span>
            <span className='ellipsis2' title={record?.title}>商品名称：{record?.title || '-'}</span>
            <span>商品价格：{FormatPrice(record?.salePrice?.amount)}</span>
        </div>)
    },
    {
        title: '库存',
        dataIndex: 'quantity',
        width: 70,
        render: val => '有货'
    },
    {
        title: '物流',
        dataIndex: 'deliveryMethod',
        width: 80,
        render: val => '自配送'
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        width: 120,
    },
    {
        title: '修改时间',
        dataIndex: 'gmtModify',
        width: 120,
    },
    {
        title: '状态',
        dataIndex: 'status',
        width: 80,
        fixed: 'right',
        render: val => val ? map[val] : ''
    }
]