import { Image, Tag } from 'antd';

const statusMap = {
    'Init': <Tag color='default'>未认证</Tag>,
    'Waiting': <Tag color='orange'>待审核</Tag>,
    'Valid': <Tag color='success'>已认证</Tag>,
    'Invalid': <Tag color='error'>已拒绝</Tag>,
}

export default [
    {
        title: '头像',
        dataIndex: 'avatarUrl',
        width: 90,
        render: val => val ? <Image
            src={val}
            style={{
                width: 60,
                height: 60,
                borderRadius: 6,
                border: '1px solid #ddd',
            }} /> : <span
            className='iconfont icon-user'
            style={{
                display: 'inline-block',
                width: 60,
                height: 60,
                border: '1px solid #ddd',
                borderRadius: 6,
                textAlign: 'center',
                lineHeight: '60px',
                fontSize: 42,
                color: '#ddd',
                background: '#f5f5f5'
            }}
        />
    },
    {
        title: '昵称',
        dataIndex: 'userName',
        width: 120,
        render: val => val || '-',
    },
    {
        title: '手机号',
        dataIndex: 'phone',
        width: 130,
        render: val => val || '-',
    },
    {
        title: '性别',
        dataIndex: 'gender',
        width: 60,
        render: val => ({ 1: '男', 2: '女' }[val] || '-')
    },
    {
        title: '店铺名称',
        dataIndex: 'userShopName',
        width: 160,
        render: val => val || '-',
    },
    {
        title: '门头照片',
        dataIndex: 'doorPlateUrl',
        width: 132,
        render: val => val ? <Image src={val} style={{
            width: 100,
            height: 60,
            borderRadius: 6,
            border: '1px solid #ddd',
            objectFit: 'cover'
        }} /> : '-'
    },
    {
        title: '营业执照',
        dataIndex: 'businessLicenseUrl',
        width: 132,
        render: val => val ? <Image
            src={val}
            style={{
                width: 100,
                height: 60,
                borderRadius: 6,
                border: '1px solid #ddd',
                objectFit: 'cover'
            }} /> : '-'
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        width: 120,
        align: 'right',
        render: val => val || '-',
    },
    {
        title: '状态',
        dataIndex: 'status',
        width: 100,
        fixed: 'right',
        render: val => statusMap[val] || '-'
    },

]