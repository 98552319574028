const formatPrice = (priceVal = '', isDecimal = true) => {
    // 移除人民币符号和千位分隔符
    let priceStr = String(priceVal).replace(/[￥¥,]/g, '');

    // 检查是否为有效数字
    if (isNaN(Number(priceStr)) || priceStr.trim() === '') {
        return '';
    }

    // 分离整数部分和小数部分
    let [integerPart, decimalPart] = priceStr.split('.');

    // 如果输入中包含小数部分而且 `isDecimal` 为 false 时，也保留原始的小数部分
    if (decimalPart !== undefined && !isDecimal) {
        decimalPart = decimalPart.substring(0, 2); // 仅保留前两位小数
    } else if (isDecimal) {
        // 需要显示小数时，确保小数部分有两位数
        decimalPart = (decimalPart || '').substring(0, 2).padEnd(2, '0');
    }

    // 组合最终结果，判断是否需要显示小数部分
    let resultStr = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;

    // 返回最终值
    return `¥${resultStr}`;
};

export default formatPrice;
