import React from 'react';
import { Navigate } from 'react-router-dom';
import { Layout } from 'antd';
import Header from '../common/Layout/Header';
import Content from '../common/Layout/Content';

const PageWrap = ({
    ReactNode,
    menuList = [],
    loginInfo = {},
}) => {

    // 未登录跳转至登录页面
    // if (!loginInfo?.phone) {
    //     return <Navigate to="/login" replace />
    // }

    return (<Layout className='console-layout-container'>
        <Header loginInfo={loginInfo} />
        <Content menuList={menuList}>
            {ReactNode}
        </Content>
    </Layout>);
};

PageWrap.defaultProps = {
    menuList: [
        {
            key: '/product-list',
            icon: <i className='iconfont icon-production' />,
            label: '商品管理',
        },
        {
            key: '/notice-list',
            icon: <i className='iconfont icon-lunbo' />,
            label: '轮播图',
        },
        {
            key: '/order-list',
            icon: <i className='iconfont icon-order' />,
            label: '订单',
        },
        {
            key: 'member-list',
            icon: <i className='iconfont icon-user' />,
            label: '会员管理',
        }
    ]

}

export default PageWrap;