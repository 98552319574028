import { useEffect, useState } from 'react';
import { Layout, Menu, Popover } from 'antd';
import './style.scss';

import { WechatOutlined } from '@ant-design/icons';

const Content = ({
    children,
    menuList = [],
}) => {

    const [isOnlyIcon, setIsOnlyIcon] = useState(false);

    const onMenuClick = ({ key }) => {
        // window.location.pathname = key;
        window.location.hash = `${key}`;
    }

    return (<Layout className='console-layout-content'>
        <div className='content-sider-wrap'>
            <Menu
                className='content-sider-menu'
                theme="dark"
                mode="inline"
                items={menuList}
                onClick={onMenuClick}
                inlineCollapsed={isOnlyIcon}
            />
            <div className='content-sider-switch' onClick={() => setIsOnlyIcon(!isOnlyIcon)}>
                <span className='switch-text'>{isOnlyIcon ? '展开' : '收起'}</span>
                <i className={`iconfont ${isOnlyIcon ? 'icon-right' : 'icon-left'} switch-icon`} />
            </div>
        </div>
        <Layout.Content className='content-main-wrap'>
            {children}
        </Layout.Content>

        {/* <div className='contact-content'>
            <Popover placement="right" trigger="hover"
                content={<div className='code-content'>
                            <img src={ require('../../../statics/qrcode.jpg') } style={{width: '150px', height: '150px'}}></img>
                        </div>}
                >
                <WechatOutlined style={{width: '40px', height: '40px', fontSize: '30px', lineHeight: '40px', paddingLeft: '5px'}} />
            </Popover>
        </div> */}

    </Layout>)
}

export default Content;