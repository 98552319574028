import { Tag } from 'antd';
import FormatPrice from './../../utils/formatPrice';

const orderMap = {
    UnPaid: <Tag color="green">待支付</Tag>,
    Paid: <Tag color="magenta">待接单</Tag>,
    Accepted: <Tag color="orange">待配送</Tag>,
    Shipped: <Tag color="processing">配送中</Tag>,
    Succeed: <Tag color="success">已完成</Tag>,
    Closed: <Tag color="default">已取消</Tag>,
};

export default [
    {
        title: '订单ID',
        dataIndex: 'orderId',
        width: 210,
    },
    {
        title: '联系人',
        dataIndex: 'addressInfo',
        width: 180,
        render: val => <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>昵称：{val.fullName}</span>
            <span>电话：{val.phone}</span>
        </div>
    },
    // {
    //     title: '商品信息',
    //     dataIndex: 'addressInfo',
    //     width: 240,
    //     render: val => <>{val?.city}{val?.district}{val?.town}{val.addressDetail}</>
    // },
    {
        title: '配送地址',
        dataIndex: 'addressInfo',
        width: 240,
        render: val => <>{val?.city}{val?.district}{val?.town}{val.addressDetail}</>
    },
    {
        title: '订单金额',
        dataIndex: 'totalShouldPayFee',
        width: 120,
        align: 'right',
        render: val => val?.amount ? FormatPrice(val?.amount) : '-',
    },
    {
        title: '下单时间',
        width: 120,
        align: 'right',
        dataIndex: 'gmtCreate',
    },
    {
        title: '订单状态',
        dataIndex: 'status',
        width: 100,
        fixed: 'right',
        render: val => orderMap[val]
    }
]