import React, { useState, useEffect } from 'react';
import { Button, Carousel, Image, message, Result } from 'antd';
import './style.scss';

import AddOrEditDialog from './AddOrEditDialog';

const NoticeList = ({
    FetchApi,
}) => {

    const [visible, setVisible] = useState(false);

    const [productList, setProductList] = useState([]);

    const [noticeList, setNoticeList] = useState([]);

    const [noticeItem, setNoticeItem] = useState({});

    const getNoticeList = async (payload) => {
        const reslut = await FetchApi('get:banner');

        if (reslut.success) {
            setNoticeList(reslut?.infoList || []);
        }
    }

    const onShowModal = () => {
        setVisible(true);
    }

    const onClose = () => {
        setVisible(false);
    }

    const onConfirm = async (paylaod) => {
        console.log(paylaod)
        const params = {
            productId: paylaod.productId || undefined,
            type: paylaod.type,
            url: paylaod.url
        }
        const reslut = await FetchApi('post:banner', params);
        if (reslut.success) {
            message.success('新增成功');
            setVisible(false);
            getNoticeList();
        }
    }

    const getProductList = async (payload) => {
        const reslut = await FetchApi('get:product', payload, '加载成功');

        if (reslut.success) {
            setProductList(reslut?.infoList || []);
        }
    }

    const onChange = () => {

    }

    // 生效 or 失效
    const onEffectHandle = async (paylaod) => {
        const params = {
            status: paylaod.status === 'Valid' ? 'Invalid' : 'Valid'
        }
        const reslut = await FetchApi(`put:banner/${paylaod.bannerId}`, params);
        if (reslut.success) {
            message.success('操作成功');
            getNoticeList();
        }
    }
    // 编辑
    const onEditHandle = (paylaod) => {
        console.log('onEditHandle', paylaod)
        setNoticeItem(paylaod);
        setVisible(true);
    }
    // 删除
    const onDeleteHandle = async (paylaod) => {
        const reslut = await FetchApi(`DELETE:banner/${paylaod.bannerId}`);
        if (reslut.success) {
            message.success('删除成功');
            getNoticeList();
        }
    }

    // 初始化页面
    useEffect(() => {
        getNoticeList();
        getProductList({ pageSize: 999, pageNumber: 1 });
    }, [])

    return <div className='notice-list-container'>
        <div className='top-panel-wrap'>
            {
                noticeList.map(item => (<div className='item-panel'>
                    <img className='item-img' src={item.url} />
                    <div className='footer'>
                        <div className='opt effect' onClick={() => onEffectHandle(item)}>{item.status === 'Valid' ? '失效' : '生效'}</div>
                        <div className='opt edit' onClick={() => onEditHandle(item)}>编辑</div>
                        <div className='opt delete' onClick={() => onDeleteHandle(item)}>删除</div>
                    </div>
                </div>))
            }
            {
                noticeList.length <= 8 && <div className='iconfont icon-plus create-btn' onClick={onShowModal} />
            }

        </div>
        <div className='bottom-panel'>

            <div className='show-panel'>
                <Carousel afterChange={onChange} autoplay={true}>
                    {
                        noticeList && noticeList.map(item => {
                            return <Image src={item.url} className='panel' preview={false} />
                        })
                    }
                </Carousel>
            </div>
        </div>

        <AddOrEditDialog
            visible={visible}
            productList={productList}
            noticeItem={noticeItem}
            onClose={onClose}
            onConfirm={onConfirm}
            FetchApi={FetchApi}
        />
    </div>
}

export default NoticeList;