import { useState } from 'react';
import { Modal, Input, InputNumber, message } from 'antd';
import './style.scss';


export default ({
    onRecharge,
    visible = {},
    onClose,
    editRecord = {},
}) => {

    // 记录充值次数
    const [chargeNum, setChargeNum] = useState(1);

    const onOk = () => {
        const Reg = new RegExp(/^[1-9]\d*$/)
        if (!Reg.test(chargeNum)) {
            message.error('次数输入内容不合法', 2);
            return false;
        }

        onRecharge({ userId: editRecord.userId, frequency: chargeNum, clickType: editRecord.clickType, phone: editRecord.phone });
    }

    return <Modal
        title='会员审核'
        open={visible}
        onOk={onOk}
        onCancel={onClose}
        okText="确认通过"
        cancelText="驳回"
        className='charge-modal'
    >

        <div className='modal-user-info'>
            <img src={editRecord.avatarUrl} className='user-img' />
            <div className='user-info'>
                <div className='info-row'>
                    <span className='info-row-key'>姓名：</span>
                    <span className='info-row-val'>{editRecord.userName}</span>
                </div>
                <div className='info-row'>
                    <span className='info-row-key'>手机号：</span>
                    <span className='info-row-val'>{editRecord.phone}</span>
                </div>
                <div className='info-row'>
                    <span className='info-row-key'>店铺名：</span>
                    <span className='info-row-val'>{editRecord.userShopName}</span>
                </div>
            </div>
        </div>
        
        <div className='modal-tip'>
            注意：确认前，请确定会员是否为真实客户！
        </div>
    </Modal>
}