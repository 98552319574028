import React, { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import SearchWrap from './../../common/SearchWrap';
import { TableColAction, TableWrap, TableHead } from './../../common/Table';
import AddModal from './AddDialog';
import './style.scss';

import config from './config';
import columns from './columns';

const ProductList = ({
    FetchApi
}) => {

    const [visible, setVisible] = useState(false);

    const [filterData, setFilterData] = useState({});
    // 表格数据
    const [tableInfo, setTableInfo] = useState({
        dataList: [],
        pageNumber: 1,
        pageSize: 10,
        total: 0,
    });

    const onEditFn = (record) => {
        console.log('编辑', record)
        window.location.hash = `/product-detail?productId=${record.productId}`;
    }

    const onOptFn = async (record) => {
        console.log('上下架', record)
        const url = record?.status === 'Online' ? `downShelf/${record.productId}` : `upShelf/${record.productId}`;
        await FetchApi(`PUT:product/${url}`, '操作成功');
        await getProductList({ pageSize: 10, pageNumber: 1 });
    }

    const onAddPage = () => {
        setVisible(true);
    }

    const onSearch = (params) => {
        setFilterData(params);
        getProductList(params);
    }

    const onReset = () => {
        setFilterData({});
        getProductList({ pageSize: 10, pageNumber: 1, title: undefined, status: undefined });
    }

    const getProductList = async (payload) => {
        const reslut = await FetchApi('get:product', {
            ...filterData,
            pageSize: tableInfo.pageSize,
            pageNumber: tableInfo.pageNumber,
            ...payload
        }, '加载成功');

        if (reslut.success) {
            console.error('1234343', reslut)
            setTableInfo({
                dataList: reslut?.infoList || [],
                total: reslut?.total || 0,
                pageNumber: payload.pageNumber || tableInfo.pageNumber,
                pageSize: payload.pageSize || tableInfo.pageSize,
            })
        }
    }

    const onAddProduct = async (payload) => {
        console.error('onAddProduct', payload)
        const result = await FetchApi('POST:product', payload, '新增成功');
        if (result.success) {
            setVisible(false);
            await getProductList({ pageSize: 10, pageNumber: 1 });
        }
    }

    const onPaginationChange = (vals) => {
        getProductList(vals);
    }

    // 操作栏上下架文案
    const onText = (record) => {
        let text = '';
        switch (record.status) {
            case 'Online':
                text = '下架';
                break;
            case 'Offline':
                text = '上架';
                break;
            default:
                text = '上架';
                break;
        }
        return text;
    }

    // 初始化页面
    useEffect(() => {
        getProductList({ pageSize: 10, pageNumber: 1 });
    }, [])

    return <>
        {/* 搜索组件 */}
        <SearchWrap config={config} onSearch={onSearch} onReset={onReset} />

        {/* 表头 */}
        <TableHead>
            <Button type="primary" onClick={onAddPage}>新增</Button>
        </TableHead>

        {/* 表格 */}
        <TableWrap
            dataSource={tableInfo.dataList}
            columns={columns}
            lastColWidth={110}
            total={tableInfo?.total}
            pageNumber={tableInfo?.pageNumber}
            pageSize={tableInfo?.pageSize}
            onPaginationChange={onPaginationChange}
            scroll={{ x: '100%' }}
        >
            <TableColAction text='编辑' onClick={onEditFn} />
            <TableColAction onText={(record) => onText(record)} isTip onClick={onOptFn} />
        </TableWrap>

        <AddModal visible={visible} FetchApi={FetchApi} onConfirm={onAddProduct} onClose={() => setVisible(false)} />
    </>
}

export default ProductList;