import { Layout, Popover, Button, Image } from 'antd';
import './style.scss';

import imgLogo from '../../../statics/logo.jpg'

const Header = ({
    className,
    loginInfo = {}
}) => {

    const onLoginOut = () => {
        console.log('退出登录')
        sessionStorage.clear();
        window.location.hash = '/login';
    }

    return (<Layout.Header
        className='console-layout-header'
    >
        <div className="header-left-wrap">
            <img className='logo' src={imgLogo}></img>
            <div className='logo-title'>孙付集山药后台管理系统</div>
        </div>
        <div className="header-middle-wrap">
            {/* 预留占位盒子 */}
        </div>
        <div className="header-right-wrap">
            {/* <div className='user-info'>
                {
                    loginInfo.avatarUrl ?
                    <img src={loginInfo.avatarUrl} style={{ width: 30, height: 30, borderRadius: 15, border: '1px solid #ddd', margin: '0 10px' }} /> :
                    <i className='iconfont icon-user' />
                }
                <span className='user-name' title={loginInfo?.brandName}>{loginInfo?.brandName}</span>
            </div> */}

            <Popover placement="topLeft" trigger="hover"
                content={<div className='tips-content'>
                            <p onClick={onLoginOut}>退出登录</p>
                        </div>}
                >
                <div className='user-info'>
                    {/* {
                        loginInfo.avatarUrl ?
                        <img src={loginInfo.avatarUrl} style={{ width: 30, height: 30, borderRadius: 15, border: '1px solid #ddd', margin: '0 10px' }} /> :
                        <i className='iconfont icon-user' />
                    } */}
                    <span className='user-name' title={loginInfo?.brandName}>{loginInfo?.brandName}</span>
                </div>
            </Popover>
        </div>
    </Layout.Header>)
}

export default Header;