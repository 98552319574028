import React, { useState, useEffect } from 'react';
import { Tabs, Form, Button } from 'antd';
import './style.scss';

import SkuInfo from './SkuInfo';
import BaseInfo from './BaseInfo';
import DetailInfo from './DetailInfo';

const ProductDetail = ({
    FetchApi,
    productTypeList: []
}) => {

    const productId = new URLSearchParams(window.location.href.split('?')[1]).get('productId');

    console.log('productId', productId);

    const [form] = Form.useForm();

    const [detailInfo, setDetailInfo] = useState({});

    /* 获取商品详情 */
    const getProductDetail = async () => {
        const result = await FetchApi(`get:product/${productId}`);
        if (result && result.info) {
            result.info.salePrice = result?.info?.salePrice?.cent && (result?.info.salePrice.cent / 100).toFixed(2) || '';
            result.info.marketPrice = result?.info?.marketPrice?.cent && (result?.info.marketPrice.cent / 100).toFixed(2) || '';
        }
        setDetailInfo(result?.info);
        return result?.info || {};
    }

    const transferMap = (data) => {
        let map = {};

        // 遍历数组
        data.forEach(item => {
            // map.set(item.text, item.values);
            map[item.text] = item.values;
        });

        return map;
    }

    /* 修改商品详情 */
    const onEditProductDetail = async (paylaod) => {
        console.log('修改商品详情', paylaod)
        const detailPath = Array.isArray(paylaod.descPath) ? paylaod.descPath : [];
        const allFeatures = Array.isArray(paylaod.features) ? transferMap(paylaod.features) : paylaod.features;
        paylaod.features = allFeatures;
        paylaod.descPath = detailPath;
        const result = await FetchApi(`put:product/${productId}`, paylaod);
        // setDetailInfo(result?.info);
        getProductDetail();
        return result?.info || {};
    }

    /* 批量新增SKU */
    const onBatchAddSku = async (paylaod) => {
        const result = await FetchApi(`post:sku/${productId}/list`, paylaod);
        if (result.success) {
            const result = await getProductDetail();
            setDetailInfo({
                ...detailInfo,
                specs: result?.specs || [],
                skuInfoList: result?.skuInfoList || [],
            });
        }
    }

    // 初始化页面
    useEffect(() => {
        getProductDetail();
    }, [])

    const onSubmit = () => {
        const formInfo = form.getFieldsValue(true);
        console.log('formInfo-->', formInfo);
        // formInfo.features = features;
        // 新增SKU
        // onBatchAddSku(formInfo.skuInfoList)
        const splitUrl = (url) => url ? url?.split('?')[0] : '';
        // const splitTxt = (path) => path ? `${path?.split('.txt')[0]}.txt` : ''
        // 新增SKU
        if (formInfo.picUrl) {
            formInfo.picUrl = formInfo.picUrl.split('?')[0];
        }
        if (formInfo?.images?.length) {
            formInfo.images = formInfo.images.filter(item => item !== null);
            formInfo.images = formInfo.images.map(item => {
                item = splitUrl(item);
                return item;
            })
        }

        if (formInfo?.descPath?.length) {
            formInfo.descPath = formInfo.descPath.filter(item => item !== null);
            formInfo.descPath = formInfo.descPath.map(item => {
                item = splitUrl(item);
                return item;
            })
        }
        // formInfo.descPath = splitTxt(formInfo.descPath);
        onEditProductDetail(formInfo);
    }

    return <div className='add-product-container'>
        <Tabs
            defaultActiveKey="1"
            type="card"
            items={[
                {
                    label: '基础信息',
                    key: '1',
                    children: <BaseInfo form={form} detailInfo={detailInfo} FetchApi={FetchApi} />,
                },
                {
                    label: 'SKU信息',
                    key: '2',
                    children: <SkuInfo form={form} detailInfo={detailInfo} />,
                },
                {
                    label: '商品详细信息',
                    key: '3',
                    children: <DetailInfo form={form} detailInfo={detailInfo} FetchApi={FetchApi} />,
                },
            ]}
        />

        <Button className='submit-btn' type='primary' onClick={onSubmit}>保存并提交</Button>
    </div>
}

ProductDetail.defaultProps = {
    productTypeList: [
        { label: '普通山药', value: 'normal' },
        { label: '小白嘴山药', value: 'small' },
        { label: '铁棍山药', value: 'iron' },
    ]
}

export default ProductDetail;