const statusOptions = [
    {
        label: '上架',
        value: 'Online'
    },
    {
        label: '草稿',
        value: 'Draft'
    },
    {
        label: '删除',
        value: 'Delete'
    },
    {
        label: '下架',
        value: 'Offline'
    }
]

export default [
    {
        id: 'title',
        label: '商品名称',
        formType: 'input',
    },
    {
        id: 'status',
        label: '商品状态',
        formType: 'select',
        options: statusOptions
    }
]