import { message } from 'antd';
import axios from 'axios';
import parseURL from './parseURL';

let baseURL = `/api/sake-shop-mgt/v1/`;

export default async (apiName, payload = {}, tipMsg = '') => {

    const debug = parseURL('debug');

    const splitApiName = String(apiName).split(':');
    apiName = splitApiName[1] || splitApiName[0];
    const METHOD = splitApiName.length > 1 ? String(splitApiName[0]).toUpperCase() : 'GET';

    // 请求是否携带cookie
    axios.defaults.withCredentials = true;

    // 若debug等于Ip, 或者 debug等于true
    if (debug || String(debug).split('.').length === 4) {
        // 解构ip是否带有端口
        const [ip = '172.16.1.201', port = '8082'] = debug ? '' : String(debug).split(':');
        baseURL = `http://${ip}:${port}/sake-shop-mgt/v1/`;
    }

    // 清空所有提示
    message.destroy();
    // loading 提示信息
    message.loading('玩命请求中...', 0);
    // 发起请求
    const result = await axios({
        baseURL,
        url: apiName,
        method: METHOD,
        params: METHOD === 'GET' && payload,
        data: METHOD !== 'GET' && payload,
        withCredentials: true,
        headers: {
            accessToken: sessionStorage.getItem('accessToken') || 'noToken',
        }
    }).then(res => res.data).catch(error => ({
        baseURL: error?.config?.baseURL,
        url: error?.config?.url,
        method: error?.config?.method,
        payload: error?.config?.payload,
        success: false,
        code: error?.response?.data?.code,
        message: error?.response?.data?.message || '请求异常',
    }));

    // 清空所有提示
    message.destroy();

    // 登录失效，跳转登录页面
    if (result.code === 'TOKEN_AES_DECRYPT_ERROR') {
        window.location.hash = '#login';
    }

    // 接口报错，页面提示
    if (!result.success) {
        message.error(result?.message || '请求异常', 2);
    }

    // 接口成功，指定提示信息
    if (tipMsg && result.success) {
        message.success(tipMsg, 1);
    }

    // 返回接口响应
    return result;

};
