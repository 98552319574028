import { Modal, DatePicker, Form, Radio, message } from 'antd';
import './style.scss';
import dayjs from 'dayjs';
import drawImg from '../DrawStickByCanvas';

export default ({
    FetchApi,
    visible = {},
    onClose,
}) => {

    const [form] = Form.useForm();

    // 确认导出
    const onConfirm = async () => {
        const vals = await form.validateFields();
        vals.startTime = `${dayjs(vals.startTime).format('YYYY-MM-DD')}+12:00:00`;
        vals.endTime = `${dayjs(vals.endTime).format('YYYY-MM-DD')}+12:00:00`;
        exportFileFn(vals);
    }

    // 导出文件
    const exportFileFn = async (vals) => {
        message.loading('下载中', 0);
        const TYPE_MAPS = {
            1: `delivery/download/distribution/list?startTime=${vals?.startTime}&endTime=${vals?.endTime}`,
            2: `delivery/stick/list`,
            3: `delivery/download/delivery/list?startTime=${vals?.startTime}&endTime=${vals?.endTime}`,
        }
        const url = TYPE_MAPS?.[vals?.type];
        if (vals?.type != 2) {
            downloadFile(url);
            return;
        }

        const result = await FetchApi(`get:${url}`, {
            startTime: vals?.startTime.replace('+', ' '),
            endTime: vals?.endTime.replace('+', ' ')
        }, '下载完成');

        message.destroy();

        if (result.success && Array.isArray(result?.deliveryInfos)) {
            result?.deliveryInfos?.forEach(drawImg)
        }

        onClose();

    }

    return <Modal
        title='订单导出'
        open={visible}
        onCancel={onClose}
        onClose={onClose}
        onOk={onConfirm}
        className='export-modal'
        okText="确定"
        cancelText="取消"
    >
        <Form form={form}>
            <Form.Item
                label="导出类型"
                name="type"
                required
                style={{ width: '100%' }}
                rules={[{ required: true, message: '请选择导出类型' }]}
            >
                <Radio.Group
                    options={[{ label: '备货单', value: 1 }, { label: '面单', value: 2 }, { label: '配送单', value: 3 }]}
                    optionType="button"
                    buttonStyle="solid"
                />
            </Form.Item>
            <Form.Item
                label="开始时间"
                name="startTime"
                required
                rules={[{ required: true, message: '请选择开始时间' }]}
            >
                <DatePicker
                    placeholder='开始时间'
                    format='YYYY-MM-DD 12:00:00'
                    style={{ width: '100%' }}
                />
            </Form.Item>
            <Form.Item
                label="结束时间"
                name="endTime"
                required
                rules={[{ required: true, message: '请选择结束时间' }]}
            >
                <DatePicker
                    placeholder='结束时间'
                    format='YYYY-MM-DD 12:00:00'
                    style={{ width: '100%' }}
                    disabledDate={(val) => val.valueOf() < dayjs(form.getFieldValue('startTime')).add(1, 'day').valueOf()}
                />
            </Form.Item>
        </Form>
    </Modal>
}


// 导出Excel
const downloadFile = (filepath) => {
    const isDebug = new URLSearchParams(window.location.href.split('?')[1]).get('debug');
    const url = `${isDebug ? 'http://172.16.1.201:8082' : '/api'}/sake-shop-mgt/v1/${filepath}`;
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.setRequestHeader('accessToken', sessionStorage.getItem('accessToken'));  // 设置自定义请求头
    xhr.responseType = 'blob';  // 以 Blob 类型接收响应数据

    xhr.onload = function () {
        if (xhr.status === 200) {
            const fileName = decodeURIComponent(xhr.getResponseHeader('Content-Disposition').split(';')[1].split('filename=')[1]);
            const blob = xhr.response;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;  // 指定下载的文件名
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);

            message.destroy();
            message.success('下载完成');
        }
    };

    xhr.onerror = function () {
        message.destroy();
        message.error('下载失败')
        console.error('An error occurred during the request.');
    };

    xhr.send();
}